<template>
  <v-content>
    <v-container>
      <v-card>
        <v-card-text>
          <v-row row wrap>
            <v-col xs12 md4>
              <v-text-field
                v-model="pass"
                label="Enter New Password"
                type="password"
                hint="Password must be at least 13 characters, contain upper and lowercase letters, numbers, and special characters"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row row wrap>
            <v-col xs12 md4>
              <v-text-field
                v-model="passConfirm"
                label="Confirm New Password"
                type="password"
                hint="Password must be at least 13 characters, contain upper and lowercase letters, numbers, and special characters"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            pa-5
            color="primary"
            @click="resetPasswordCheck"
            :disabled="!(pass && passConfirm)"
          >Reset password</v-btn>
        </v-card-actions>
      </v-card>

      <v-dialog v-model="dialog" max-width="850px">
        <v-card style="text-align:center">
          <v-card-text>
            <v-layout row wrap>
              <v-flex xs12>
                {{message}}
                <template v-for="(e,idx) in errors">
                  <div :key="idx">
                    {{e}}
                    <br />
                    <br />
                  </div>
                </template>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </v-content>
</template>
<script>
import { authMethods, authComputed } from '@state/helpers'
export default {
  page: {
    title: 'Reset Password',
  },
  data() {
    return {
      message: '',
      errors: [],
      pass: '',
      passConfirm: '',
      dialog: false,
    }
  },
  created() {},
  computed: {
    ...authComputed,
  },
  methods: {
    resetPasswordCheck() {
      this.errors = []
      if (this.pass == this.passConfirm) {
        this.resetPassword()
      } else {
        this.hasSubmitted = true
        this.message = 'Passwords must match'
        this.dialog = true
      }
    },
    resetPassword() {
      this.errors = []
      let email = this.$route.query.email
      const self = this
      if (email && this.$route.query.token) {
        let request = {
          user: {
            Email: email,
          },
          token: this.$route.query.token,
          password: this.pass,
        }
        axios
          .post(`${this.identityUrl}/api/Auth/ResetPassword`, request)
          .then((response) => {
            this.message = `Password for account ${email} has been reset`
            this.dialog = true
            this.pass = ''
            this.passConfirm = ''
          })
          .catch((e) => {
            this.message = `There was an error resetting password for ${email} -`
            let err = e.response.data
            if (JSON.stringify(err).substring(0, 1) != '{') {
              self.errors.push(err)
            } else {
              Object.keys(err).forEach((val) => {
                if (Array.isArray(err[val])) {
                  err[val].forEach((i) => {
                    if (i.Description) {
                      self.errors.push(i.Description)
                    } else {
                      self.errors.push(i)
                    }
                  })
                } else {
                  self.errors.push(err[val])
                }
              })
            }
            this.dialog = true
          })
      } else {
        this.message = `Unable to reset password`
        this.dialog = true
      }
    },
  },
}
</script>

<style>
</style>
